var core = require('../../../../../node_modules/foundation-sites/dist/js/plugins/foundation.core.js');

var utilBox = require('../../../../../node_modules/foundation-sites/dist/js/plugins/foundation.util.box.js');
var utilKeyboard = require('../../../../../node_modules/foundation-sites/dist/js/plugins/foundation.util.keyboard.js');
var utilMediaQuery = require('../../../../../node_modules/foundation-sites/dist/js/plugins/foundation.util.mediaQuery.js');
var utilMotion = require('../../../../../node_modules/foundation-sites/dist/js/plugins/foundation.util.motion.js');
var utilNest = require('../../../../../node_modules/foundation-sites/dist/js/plugins/foundation.util.nest.js');
var utilTimerAndImageLoader = require('../../../../../node_modules/foundation-sites/dist/js/plugins/foundation.util.timerAndImageLoader.js');
var utilTouch = require('../../../../../node_modules/foundation-sites/dist/js/plugins/foundation.util.touch.js');
var utilTriggers = require('../../../../../node_modules/foundation-sites/dist/js/plugins/foundation.util.triggers.js');

var accordion = require('../../../../../node_modules/foundation-sites/dist/js/plugins/foundation.accordion.js');
var accordionMenu = require('../../../../../node_modules/foundation-sites/dist/js/plugins/foundation.accordionMenu.js');
var dropdown = require('../../../../../node_modules/foundation-sites/dist/js/plugins/foundation.dropdown.js');
var dropdownMenu = require('../../../../../node_modules/foundation-sites/dist/js/plugins/foundation.dropdownMenu.js');
var interchange = require('../../../../../node_modules/foundation-sites/dist/js/plugins/foundation.interchange.js');
var offcanvas = require('../../../../../node_modules/foundation-sites/dist/js/plugins/foundation.offcanvas.js');
var reveal = require('../../../../../node_modules/foundation-sites/dist/js/plugins/foundation.reveal.js');
var sticky = require('../../../../../node_modules/foundation-sites/dist/js/plugins/foundation.sticky.js');
var tooltip = require('../../../../../node_modules/foundation-sites/dist/js/plugins/foundation.tooltip.js');

jQuery(function($) {
  $(document).foundation();
});
