var widget    = require('jquery-ui/ui/widget');
var version   = require('jquery-ui/ui/version');
var plugin    = require('jquery-ui/ui/plugin');
var data      = require('jquery-ui/ui/data');
var sae       = require('jquery-ui/ui/safe-active-element');
var sb        = require('jquery-ui/ui/safe-blur');
var sp        = require('jquery-ui/ui/scroll-parent');
var ds        = require('jquery-ui/ui/disable-selection');
var mouse     = require('jquery-ui/ui/widgets/mouse');
var draggable = require('jquery-ui/ui/widgets/draggable');
var droppable = require('jquery-ui/ui/widgets/droppable');
var sortable  = require('jquery-ui/ui/widgets/sortable');
